.dialog-container {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
  z-index: 2;
  width: 90%;
  max-width: 280px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform, opacity; /* to use hardware acceleration */
}

.dialog-container.visible {
  opacity: 1;
  transform: translateY(0);
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 12px 12px 0 0;
}

.header-text {
  font-weight: 600;
  font-size: 1.1em;
  color: #1a1a1a;
}

.close-button {
  font-size: 1.5em;
  color: #666;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.close-button:hover {
  background: rgba(0, 0, 0, 0.05);
  color: #333;
}

.dialog-body {
  padding: 16px;
  color: #4a4a4a;
  font-size: 0.95em;
  line-height: 1.5;
}

.station-list {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.stations-grid {
  max-height: 240px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 12px;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.station-item {
  padding: 8px 12px;
  border-radius: 6px;
  background: #f8f9fa;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.station-item:hover {
  background: #f0f2f5;
}

.runtime-section {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 0 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 4px;
}

.runtime-label {
  font-weight: 500;
  color: #1a1a1a;
}

.runtime-picker {
  width: 100px;
}

.dialog-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 12px 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 0 0 12px 12px;
}

.save-button {
  background-color: #1890ff;
  border: none;
  border-radius: 6px;
  color: #fff;
  padding: 6px 16px;
  margin-right: 8px;
  cursor: pointer;
  font-size: 0.9em;
  font-weight: 500;
  transition: all 0.2s ease;
}

.save-button:hover {
  background-color: #096dd9;
}

.cancel-button {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  color: #595959;
  padding: 6px 16px;
  cursor: pointer;
  font-size: 0.9em;
  font-weight: 500;
  transition: all 0.2s ease;
}

.cancel-button:hover {
  background-color: #f5f5f5;
  border-color: #bfbfbf;
}

/* Custom Scrollbar */
.stations-grid::-webkit-scrollbar {
  width: 6px;
}

.stations-grid::-webkit-scrollbar-track {
  background: transparent;
}

.stations-grid::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 3px;
  transition: background 0.2s ease;
}

.stations-grid::-webkit-scrollbar-thumb:hover {
  background: #bfbfbf;
}
