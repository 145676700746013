#filter-button {
  background-color: #1976d2;
  color: white;
  font-size: 16px;
  padding: 8px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  margin-left: 10px;
}

#filter-button:hover {
  background-color: #1565c0;
}

.data {
  height: 100%;
  padding: 2em;
}
