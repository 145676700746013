.header-container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #001529;
}

.main-menu {
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  overflow-x: auto;
}

.menu-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.menu-icon {
  vertical-align: middle;
  font-size: 1rem;
}

.ant-menu-item .menu-item,
.ant-menu-submenu-title .menu-item {
  padding: 0 8px;
}

.ant-menu-title-content {
  display: flex;
  align-items: center;
}

.button-container {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 10px;
  white-space: nowrap;
}

.rain-hold,
.rain-hold-active {
  color: white;
  border: none;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 5px 0 0 5px;
  margin: 0 !important;
  font-size: 14px;
  height: 32px;
}

.rain-hold {
  background-color: #1890ff !important;
}

.rain-hold-active {
  background-color: #ff4d4f !important;
}

.deploy-menu {
  height: 32px;
  border-bottom: none !important;
  line-height: 30px;
}

.deploy-menu.ant-menu .ant-menu-item {
  padding: 0 15px !important;
  margin: 0 !important;
  height: 32px !important;
  line-height: 32px !important;
  border-radius: 0 5px 5px 0 !important;
  border-left: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.deploy-menu.ant-menu .ant-menu-item-selected {
  background-color: transparent !important;
  color: rgba(255, 255, 255, 0.85) !important;
}

.deploy-menu.ant-menu .ant-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
}

@media (max-width: 768px) {
  .main-menu .ant-menu-item,
  .main-menu .ant-menu-submenu {
    padding: 0 8px !important;
  }

  .button-container {
    flex-shrink: 0;
  }

  .main-menu .ant-menu-submenu-title .menu-item span:not(.menu-icon),
  .main-menu .ant-menu-item .menu-item span:not(.menu-icon) {
    display: none;
  }

  .rain-hold,
  .rain-hold-active,
  .deploy-menu.ant-menu .ant-menu-item {
    padding: 0 10px !important;
  }
}

@media (max-width: 480px) {
  .rain-hold,
  .rain-hold-active {
    font-size: 12px;
    padding: 0 8px !important;
  }

  .deploy-menu.ant-menu .ant-menu-item {
    font-size: 12px;
    padding: 0 8px !important;
  }

  .deploy-menu .menu-item span {
    display: inline !important;
  }
}
